import React, {useEffect, useState} from 'react';
import {graphql, navigate} from 'gatsby';
import {createAuthedApi, parseApiError, ApiError} from '../../api';
import {AxiosError} from 'axios';
import * as ReactRecurly from '@recurly/react-recurly';
import {RecurlyError} from 'recurly__recurly-js';
import {PageProps} from '../../types';
import PageWidth from '../../components/PageWidth';
import {Layout} from '../../components/Layout';
import SEO from '../../components/SEO';
import {PaymentPageQuery} from './__generated__/PaymentPageQuery';
import {
  PageWrapper,
  PageHeader,
  PageDescription,
  Heading,
  Spacer,
  Box,
  Skeleton,
  PageCentre,
} from '../../styles/page';
import {
  getPageData,
  getRecurlyPublicKeyFromData,
  getApiUrlFromData,
} from '../../utils';
import {useAuth} from '../../services/auth';
import {useHasMounted} from '../../hooks/use-has-mounted';
import {
  PaymentsApi,
  UserSubscriptionRequest,
} from '@focusrite-novation/ampify-api';
import {PaymentFormRecurlyContainer} from '../../components/PaymentFormRecurlyContainer/PaymentFormRecurlyContainer';
import {createApi} from '../../api';
import {parseCountriesFromResponse} from '../../utils/plans';

type PaymentPageProps = PageProps<PaymentPageQuery>;

const ThreeDSecureAction = (ReactRecurly as any).ThreeDSecureAction;

const PaymentPage = (props: PaymentPageProps) => {
  const {
    user,
    isLoggedIn,
    getUserInfo,
    isSubscribedToPlan,
    attemptedFetch,
  } = useAuth();
  const monthlyPlanId = props.data.planData.edges[0].node.premiumId || '';
  const [chosenPlanId, setChosenPlanId] = useState<string>(monthlyPlanId);
  const hasMounted = useHasMounted();
  const [errorMessage, setErrorMessage] = useState('');
  const [threeDToken, setThreeDToken] = useState<string>('');
  const [billingDuration, setBillingDuration] = useState<string>('month');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [formData, setFormData] = useState<UserSubscriptionRequest>();
  const [allowedCountries, setAllowedCountries] = useState([]);

  const data = getPageData(props.data);

  const handleThreeDSecureToken = (token: {id: string}) => {
    const updatedData = {
      ...formData,
      threeDSecureActionResultTokenId: token.id,
    } as UserSubscriptionRequest;

    handleSubmit(updatedData);
  };

  const handleSubscriptionError = (error: RecurlyError | ApiError) => {
    setThreeDToken('');
    console.error(error);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (data: UserSubscriptionRequest) => {
    setErrorMessage('');
    setFormData(data);
    setIsSubmitting(true);

    try {
      const api = createAuthedApi(PaymentsApi, getApiUrlFromData(props.data));
      await api.subscribe({...data, planId: chosenPlanId});

      navigate('/subscription/success?method=card');
    } catch (error) {
      setIsSubmitting(false);

      try {
        const apiError = parseApiError(error);
        if (apiError.statusCode) {
          if (apiError.errorCode === 'ThreeDSecureActionRequired') {
            setThreeDToken(
              (error as AxiosError).response.data.threeDSecureActionTokenId
            );
          } else {
            handleSubscriptionError(apiError);
          }
        }
      } catch (error) {
        console.error(error);
        setErrorMessage(
          'Sorry, there was a problem creating your subscription.'
        );
      }
    }
  };

  useEffect(() => {
    if (isSubscribedToPlan(monthlyPlanId)) {
      navigate('/subscription/success/?subscribed');
    }
  }, [isSubscribedToPlan, props.data]);

  useEffect(() => {
    async function refreshUserData() {
      if (!attemptedFetch) {
        await getUserInfo(getApiUrlFromData(props.data));
      }
    }
    refreshUserData();
  }, [createAuthedApi, getUserInfo, attemptedFetch]);

  useEffect(() => {
    async function updatePlan() {
      const planId =
        new URLSearchParams(window?.location?.search).get('planId') ||
        monthlyPlanId;

      setChosenPlanId(planId);

      if (planId !== '') {
        const api = createApi(PaymentsApi, getApiUrlFromData(props.data));
        try {
          const plansResponse = await api.getPlans();
          const plan = plansResponse.data.plans.find((plan) => {
            return plan.id === planId;
          });

          if (plan?.duration?.interval) {
            setBillingDuration(
              plan.duration.interval.length === 12 &&
                plan.duration.interval.unit === 'months'
                ? 'year'
                : 'month'
            );
          }
        } catch (error) {
          // If we can't get to the API we fall back to having the duration be months, which matches the default plan
          console.error(error);
        }
      }
    }

    updatePlan();
  }, []);

  useEffect(() => {
    if (attemptedFetch && isLoggedIn === false) {
      const redirect = `/subscription/payment?planId=${chosenPlanId}`;
      navigate(`/login?continue=${encodeURIComponent(redirect)}`);
    }
  }, [attemptedFetch, isLoggedIn]);

  useEffect(() => {
    async function fetchAllowedCountries() {
      try {
        const api = createApi(PaymentsApi, getApiUrlFromData(props.data));
        const countriesResponse = (await api.getSupportedCountries()).data;
        const countries = countriesResponse.hasOwnProperty('countries')
          ? // @ts-ignore
            parseCountriesFromResponse(countriesResponse.countries)
          : countriesResponse;

        setAllowedCountries(countries);
      } catch (error) {
        // if this endpoint fails we can safely ignore it as the server
        // will reject any incorrect country - this code is mostly for UX
      }
    }

    fetchAllowedCountries();
  }, []);

  return (
    <Layout>
      <SEO
        title={data.pageTitle}
        keywords={data.metaKeywords.split(',')}
        description={data.metaDescription}
      />
      <PageWrapper grey>
        <PageWidth narrow>
          <PageHeader>
            <Heading>{data.pageTitle}</Heading>
            <PageDescription>{data.pageDescription}</PageDescription>
          </PageHeader>
          <Spacer />

          {user.id && hasMounted ? (
            <Box>
              <ReactRecurly.RecurlyProvider
                publicKey={getRecurlyPublicKeyFromData(props.data)}
              >
                {threeDToken?.length > 0 && isSubmitting === false ? (
                  <ThreeDSecureAction
                    actionTokenId={threeDToken}
                    onToken={handleThreeDSecureToken}
                    className="recurly-three-d-secure-action"
                    onError={handleSubscriptionError}
                  />
                ) : isSubmitting === true ? (
                  <PageCentre style={{margin: '2rem 0'}}>
                    <h2>One moment please...</h2>
                    <p>We are submitting your payment</p>
                  </PageCentre>
                ) : (
                  <ReactRecurly.Elements>
                    <PaymentFormRecurlyContainer
                      user={user}
                      supportedCountries={allowedCountries}
                      submitButtonText="Purchase Plan"
                      planId={chosenPlanId}
                      subscribe={handleSubmit}
                      error={errorMessage}
                      useUpdateBillingForm={false}
                      billingDuration={billingDuration}
                    />
                  </ReactRecurly.Elements>
                )}
              </ReactRecurly.RecurlyProvider>
            </Box>
          ) : (
            <Box>
              <Skeleton style={{width: '50%'}} />
              <Spacer />
              <Skeleton style={{width: '45%'}} />
              <Spacer />
              <Skeleton style={{width: '47%'}} />
              <Spacer />
              <Skeleton style={{width: '52%'}} />
            </Box>
          )}
        </PageWidth>
      </PageWrapper>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PaymentPageQuery {
    en: allContentfulAmpifyWebSubscriptionPayment(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          pageTitle
          pageDescription
          metaKeywords
          metaDescription
          premiumDescription
          switchPlanButton
          purchaseButton
        }
      }
    }
    planData: allContentfulAmpifyWebPlans {
      edges {
        node {
          premiumId
        }
      }
    }
    site {
      siteMetadata {
        apiUrl
        recurlyPublicKey
      }
    }
  }
`;

export default React.memo(PaymentPage);
